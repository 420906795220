import React, { useCallback, useEffect, useMemo, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./PageDraft.css";
import Header from "../../components/common/Header";
import Page from "../page/Page";
import "../../theme/quill.css";
import { API, Storage } from "aws-amplify";
import {
  createPage,
  deletePageDraft,
  updatePageDraft,
} from "../../graphql/mutations";
import { getPageDraft } from "../../graphql/queries";
import { useNavigate, useParams } from "react-router-dom";
import replaceKeysWithSignedUrls from "../../functions/replaceKeysWithSignedUrls";
import replaceSignedUrlsWithKeys from "../../functions/replaceSignedUrlsWithKeys";
import ImageUploader from "quill-image-uploader";
import ImageResize from "quill-image-resize-module-react";
import { useAuthContext } from "../../context/AuthContext";
import axios from "axios";

const Quill = ReactQuill.Quill;

const Size = Quill.import('attributors/style/size');
Size.whitelist = ['12px', '14px', '16px', '18px', '20px', '22px', '24px', '28px', '32px', '36px', '40px', '44px', '48px'];
Quill.register(Size, true);

const Font = Quill.import("formats/font");
Font.whitelist = ["Roboto", "sans-serif", "Georgia"];
Quill.register(Font, true);

Quill.register("modules/imageUploader", ImageUploader);
Quill.register("modules/ImageResize", ImageResize);

const formats = [
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "video",
  "color",
  "background",
  "align",
];

const PageDraft = ({}) => {
  let quillRef = null; // Quill instance
  const [modules, setModules] = useState(); // Quill modules

  const [pageDraft, setPageDraft] = useState();
  const [label, setLabel] = useState("");
  const [summary, setSummary] = useState("");
  const [thumbnail, setThumbnail] = useState("");

  const [tags, setTags] = useState("");

  const [editorHtml, setEditorHtml] = useState("");
  const [editorContent, setEditorContent] = useState({});
  const [urlKeyMap, setUrlKeyMap] = useState({}); // Map of urls to keys

  const { pageDraftID } = useParams();
  const navigate = useNavigate();
  const { authUser, accessLevel, setSnackbar } = useAuthContext();

  //check if auth user is in a group that has permission to publish

  useEffect(() => {
    fetchPageDraft();
    setModules(getModules());
  }, []);

  //populate quill with page content
  const fetchPageDraft = async () => {
    const pageDraftResponse = await API.graphql({
      query: getPageDraft,
      variables: {
        id: pageDraftID,
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });

    const pageDraft = pageDraftResponse?.data?.getPageDraft;
    if (!pageDraft) return;

    setPageDraft(pageDraft);

    const formattedContent = JSON.parse(pageDraft?.content);
    // const formattedContent = pageDraft.content;

    if (formattedContent) {
      const [formattedContentWithUrls, newUrlKeyMap] =
        await replaceKeysWithSignedUrls(formattedContent);
      setEditorContent(formattedContentWithUrls || {});
      setUrlKeyMap(newUrlKeyMap);

      // Convert Delta to HTML and set editor HTML
      const html = convertDeltaToHtml(formattedContentWithUrls || {});
      setEditorHtml(html);
    }

    //set input fields
    setLabel(pageDraft.label);
    setTags(pageDraft.tags);
    setSummary(pageDraft.summary);
    setThumbnail(pageDraft.thumbnail);
  };

  const convertDeltaToHtml = (delta) => {
    const container = document.createElement("div");
    const quill = new Quill(container);
    quill.setContents(delta);
    const html = quill.root.innerHTML;
    container.remove(); // Clean up the created DOM element
    return html;
  };

  const handleChange = (content, delta, source, editor) => {
    setEditorHtml(editor.getHTML()); // Get editor's html
    setEditorContent(editor.getContents()); // Get editor's text
  };

  //will save page as json to database using quill delta format
  const handleSave = async () => {
    //before saving, convert all of the urls in editor content to the keys
    const convertedContent = replaceSignedUrlsWithKeys(
      editorContent,
      urlKeyMap
    );
    const formattedContent = JSON.stringify(convertedContent);

    try {
      if (thumbnail?.name) {
        await Storage.put(
          `page_drafts/${pageDraftID}/${thumbnail.name}`,
          thumbnail,
          {
            contentType: thumbnail.type,
            ACL: "authenticated-read",
          }
        );
      }

      await API.graphql({
        query: updatePageDraft,
        variables: {
          input: {
            label: label,
            tags: tags + label.split(" ").join(","),
            summary: summary,
            thumbnail: thumbnail?.name
              ? `page_drafts/${pageDraftID}/${thumbnail.name}`
              : thumbnail,
            id: pageDraftID,
            content: formattedContent,
          },
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      setSnackbar({ message: "Draft saved" });
    } catch (err) {
      console.log(err);
      setSnackbar({ message: err.message, type: "error" });
    }
  };

  const handleSetPending = async () => {
    await handleSave();

    try {
      //check authUser group to determine access level
      await axios.put(
        process.env.REACT_APP_PAGEDRAFT_API_ENDPOINT,
        {
          pageDraftID: pageDraftID,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authUser.signInUserSession.accessToken.jwtToken}`,
          },
        }
      );
      setSnackbar({ message: "Draft marked for review" });
    } catch (err) {
      setSnackbar({ message: err.message, type: "error" });
      console.log(err);
    }
  };

  const handlePublish = async () => {
    await handleSave();

    try {
      await axios.post(process.env.REACT_APP_PUBLISH_PAGEDRAFT_API_ENDPOINT, {
        pageDraftID: pageDraftID,

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authUser.signInUserSession.accessToken.jwtToken}`,
        },
      });
      setSnackbar({ message: "Draft published" });
      navigate("/");
    } catch (err) {
      setSnackbar({ message: err.message, type: "error" });
      console.log(err);
    }
  };

  const deleteDraft = async () => {
    try {
      await API.graphql({
        query: deletePageDraft,
        variables: {
          input: {
            id: pageDraftID,
          },
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      navigate("/contributor_dashboard");
      setSnackbar({ message: "Draft deleted" });
    } catch (err) {
      setSnackbar({ message: err.message, type: "error" });
      console.log(err);
    }
  };

  //image handler for quill
  const imageUploader = useCallback(
    (file) => {
      return new Promise((resolve, reject) => {
        const filePath = `page_drafts/${pageDraftID}/${file.name}`;

        Storage.put(filePath, file, {
          contentType: file.type,
          ACL: "authenticated-read",
        })
          .then(async (result) => {
            console.log("File uploaded:", result);

            const signedUrl = await Storage.get(filePath);

            //update the url key map
            setUrlKeyMap((old) => {
              return {
                ...old,
                [signedUrl]: filePath,
              };
            });

            setSnackbar({ message: "Image uploaded" });

            resolve(signedUrl); // resolve the promise with the signed URL
          })
          .catch((err) => {
            setSnackbar({ message: err.message, type: "error" });
            console.error("Error uploading file:", err);
            reject("Upload failed");
          });
      });
    },
    [pageDraftID, urlKeyMap]
  );

  const getModules = () => {
    return {
      toolbar: {
        container: [
          ["bold", "italic", "underline", "strike"],
          [{ 'size': ['12px', '14px', '16px', '18px', '20px', '22px', '24px', '28px', '32px', '36px', '40px', '44px', '48px'] }],
         
          [{ list: "ordered" }, { list: "bullet" }],
          [{ image: "customImageHandler" }],
          ["link"],
          [{ color: [] }, { background: [] }],
          [{ indent: "-1" }, { indent: "+1" }],
          [{ align: [] }],
          [{ font: Font.whitelist }],
          ["blockquote"],
          ["clean"],
        ],
      },
      imageUploader: {
        upload: imageUploader,
      },
      //   ImageResize: {
      //    modules: [ 'Resize', 'DisplaySize', 'Toolbar' ]
      //  }
    };
  };

  if (!modules) return null;

  return (
    <div
      style={{
        width: "100dvw",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Header />
      <h1 style={{ textAlign: "center", marginTop: "40px" }}>
        Page Editor • {pageDraft?.status}
      </h1>

      <div className="page-editor-container">
        <ReactQuill
          ref={(el) => {
            quillRef = el ? el.getEditor() : null;
          }}
          value={editorHtml}
          onChange={handleChange}
          modules={modules}
          formats={formats}
          bounds={".app"}
          theme="snow"
        />
        <div
          style={{
            width: "100%",
            height: "3px",
            backgroundColor: "black",
            margin: "40px 0px",
          }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              flexWrap: "wrap",
            }}
          >
            <div>
              <h4 style={{}}>label</h4>
              <input
                placeholder="Page label"
                onChange={(e) => {
                  setLabel(e.target.value);
                }}
                value={label}
              />
              <h4>summary</h4>
              <input
                placeholder="Page summary"
                onChange={(e) => {
                  setSummary(e.target.value);
                }}
                value={summary}
              />
            </div>
            <div>
              <h4>tags</h4>
              <input
                placeholder="tags,split,by,commas"
                onChange={(e) => {
                  setTags(e.target.value);
                }}
                value={tags}
              />
              {/*thumbnail upload button*/}
              <h4>thumbnail</h4>
              <input
                type="file"
                onChange={async (e) => {
                  setThumbnail(e.target.files[0]);
                }}
              />
              <h5 style={{ margin: 0 }}>
                {thumbnail?.name
                  ? thumbnail.name
                  : //from 2nd slash onwards
                    thumbnail?.substring(thumbnail?.lastIndexOf("/") + 1)}
              </h5>
            </div>
          </div>
          <div
            style={{
              marginTop: "50px",
              alignSelf: "center",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-around",
              width: "100%",
            }}
          >
            <button onClick={handleSave}>Save Draft</button>
            <button onClick={handleSetPending}>Mark for review</button>
            {(pageDraft?.status === "DRAFT" ||
              !pageDraft?.status ||
              accessLevel === "ADMIN" ||
              accessLevel === "MODERATOR") && (
                <button onClick={deleteDraft}>Delete</button>
              )}

            {accessLevel === "ADMIN" || accessLevel === "MODERATOR" ? (
              <button onClick={handlePublish}>Publish</button>
            ) : null}
          </div>
        </div>
        <div
          style={{
            width: "100%",
            height: "3px",
            backgroundColor: "black",
            margin: "40px 0px",
          }}
        />

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
          }}
        >
          <h3 style={{ color: "red" }}>Preview</h3>
          <Page previewContent={editorContent} />
        </div>
      </div>
    </div>
  );
};

export default PageDraft;
