/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getPageDraft = /* GraphQL */ `
  query GetPageDraft($id: ID!) {
    getPageDraft(id: $id) {
      id
      label
      summary
      thumbnail
      content
      tags
      status
      contributorID
      pageID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPageDrafts = /* GraphQL */ `
  query ListPageDrafts(
    $filter: ModelPageDraftFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPageDrafts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        label
        summary
        thumbnail
        content
        tags
        status
        contributorID
        pageID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const pageDraftsByContributorID = /* GraphQL */ `
  query PageDraftsByContributorID(
    $contributorID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPageDraftFilterInput
    $limit: Int
    $nextToken: String
  ) {
    pageDraftsByContributorID(
      contributorID: $contributorID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        label
        summary
        thumbnail
        content
        tags
        status
        contributorID
        pageID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const pageDraftsByPageID = /* GraphQL */ `
  query PageDraftsByPageID(
    $pageID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPageDraftFilterInput
    $limit: Int
    $nextToken: String
  ) {
    pageDraftsByPageID(
      pageID: $pageID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        label
        summary
        thumbnail
        content
        tags
        status
        contributorID
        pageID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getContributor = /* GraphQL */ `
  query GetContributor($id: ID!) {
    getContributor(id: $id) {
      id
      Pages {
        items {
          id
          label
          summary
          thumbnail
          content
          tags
          status
          contributorID
          pageID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      name
      accessLevel
      PageDrafts {
        items {
          id
          label
          summary
          thumbnail
          content
          tags
          status
          contributorID
          pageID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listContributors = /* GraphQL */ `
  query ListContributors(
    $filter: ModelContributorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContributors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Pages {
          nextToken
          __typename
        }
        name
        accessLevel
        PageDrafts {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPage = /* GraphQL */ `
  query GetPage($id: ID!) {
    getPage(id: $id) {
      id
      label
      summary
      thumbnail
      content
      tags
      status
      contributorID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPages = /* GraphQL */ `
  query ListPages(
    $id: ID
    $filter: ModelPageFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPages(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        label
        summary
        thumbnail
        content
        tags
        status
        contributorID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const pagesByLabel = /* GraphQL */ `
  query PagesByLabel(
    $label: String!
    $sortDirection: ModelSortDirection
    $filter: ModelPageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    pagesByLabel(
      label: $label
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        label
        summary
        thumbnail
        content
        tags
        status
        contributorID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
