import React, { useEffect, useState } from "react";
import SearchBar from "./components/SearchBar";
import Header from "../../components/common/Header";
import colors from "../../theme/colors";
import PageGrid from "./components/PageGrid";
import { API, Analytics } from "aws-amplify";
import Footer from "../../components/common/Footer";
import { GridLoader } from "react-spinners";

const listPages = /* GraphQL */ `
  query ListPages(
    $filter: ModelPageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        label
        summary
        thumbnail
        tags
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

const Home = () => {
  const [pages, setPages] = useState([]);
  const [filteredTopics, setFilteredTopics] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const getPages = async () => {
    const pagesResponse = await API.graphql({ query: listPages });
    const pages = pagesResponse.data.listPages.items;
    setPages(pages);

    const timestamp = new Date().toISOString();
    Analytics.record({ name: "pageView", attributes: { page: "home", timestamp } }); 
  };

  useEffect(() => {
    getPages();
  }, []);

  const pageWidth = window.innerWidth;
  const isMobile = pageWidth < 768;

  return (
    <div>
      <div
        style={{
          width: "100vw", //if mobile height undefined, otherwise 100vh
          height: isMobile ? undefined : "100vh",
          backgroundColor: colors.defaultBackground,
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Header />
        <SearchBar
          setFilteredResults={setFilteredTopics}
          data={pages}
          setSearchTerm={setSearchTerm}
        />
        {searchTerm && (
          <h4 style={{ textAlign: "center" }}>
            {filteredTopics.length} results
          </h4>
        )}
        {
           !pages.length && <GridLoader style={{marginTop: '20%'}} color="black" />
        }
        <PageGrid pages={filteredTopics.length ? filteredTopics : pages} />
      </div>
      <Footer />
    </div>
  );
};

export default Home;

// const pages = [
//     {
//         title: "Mind Controlling Parasitic Worms",
//         summary: "Parasitic worms that control the minds of their hosts.",
//         image: "https://source.unsplash.com/400x600/?worm,parasite",
//         content:
//             "<h1>Mind Controlling Parasitic Worms</h1><p>Parasitic worms that control the minds of their hosts, altering their behaviors and actions to the benefit of the parasite.</p>",
//         keywords: "worm,parasite,mind,control,host",
//     },
//     {
//         title: "World Government Alien Contact",
//         summary: "Evidence suggesting global leaders are in contact with extraterrestrial beings.",
//         image: "https://source.unsplash.com/400x600/?government,alien",
//         content:
//             "<h1>World Government Alien Contact</h1><p>Investigating the compelling signs that world governments have made contact with alien civilizations, and the possible agreements or collaborations that have ensued.</p>",
//         keywords: "government,alien,contact,world,leaders",
//     },
//     {
//         title: "Chemtrails and Mind Control",
//         summary: "The truth behind chemtrails and their connection to mass mind control.",
//         image: "https://source.unsplash.com/400x600/?chemtrails,sky",
//         content:
//             "<h1>Chemtrails and Mind Control</h1><p>Unraveling the sinister plan behind the mysterious lines in the sky. Are they part of a large-scale attempt at controlling the masses?</p>",
//         keywords: "chemtrails,mind,control,sky,chemical",
//     },
//     {
//         title: "Illuminati and the New World Order",
//         summary: "How the secret society is orchestrating global events to establish a New World Order.",
//         image: "https://source.unsplash.com/400x600/?illuminati,society",
//         content:
//             "<h1>Illuminati and the New World Order</h1><p>Uncovering the hidden hands that manipulate world events, and their sinister plans for a global government.</p>",
//         keywords: "illuminati,new world order,secret,society,global",
//     },
//     {
//         title: "Moon Landing Hoax",
//         summary: "Evidence supporting the theory that the moon landing was staged.",
//         image: "https://source.unsplash.com/400x600/?moon,landing",
//         content:
//             "<h1>Moon Landing Hoax</h1><p>Examining the anomalies and inconsistencies in the official narrative that point towards a grand deception in the history of space exploration.</p>",
//         keywords: "moon,landing,hoax,space,NASA",
//     },
//     {
//         title: "MKUltra and Modern Mind Control",
//         summary: "The continuation of MKUltra experiments in modern day society.",
//         image: "https://source.unsplash.com/400x600/?mind,control",
//         content:
//             "<h1>MKUltra and Modern Mind Control</h1><p>Exploring the evidence that suggests MKUltra-type mind control experiments didn't end but rather evolved, and are still being conducted in secret today.</p>",
//         keywords: "MKUltra,mind,control,experiment,secret",
//     },
//     {
//         title: "Flat Earth Theory",
//         summary: "Arguments supporting the idea that the earth is flat.",
//         image: "https://source.unsplash.com/400x600/?earth,flat",
//         content:
//             "<h1>Flat Earth Theory</h1><p>Delving into the evidence that challenges the mainstream scientific consensus, and supports the notion that the Earth is flat.</p>",
//         keywords: "earth,flat,theory,science,geography",
//     },
//     {
//         title: "The Bermuda Triangle Mysteries",
//         summary: "Exploring unexplained phenomena and disappearances in the Bermuda Triangle.",
//         image: "https://source.unsplash.com/400x600/?bermuda,triangle",
//         content:
//             "<h1>The Bermuda Triangle Mysteries</h1><p>Investigating the enigmatic area known for mysterious disappearances of ships and aircraft. Is there a logical explanation or is something more sinister at play?</p>",
//         keywords: "bermuda,triangle,mystery,disappearance,phenomena",
//     },
//     {
//         title: "Area 51 and Alien Technology",
//         summary: "The secrets and alien technologies concealed at Area 51.",
//         image: "https://source.unsplash.com/400x600/?area51,alien",
//         content:
//             "<h1>Area 51 and Alien Technology</h1><p>Unveiling the classified experiments and alleged extraterrestrial technologies hidden within the most infamous secret base in the world.</p>",
//         keywords: "area51,alien,technology,secret,base",
//     },
//     {
//         title: "The Hollow Earth Theory",
//         summary: "The theory that Earth is hollow and harbors a hidden world.",
//         image: "https://source.unsplash.com/400x600/?hollow,earth",
//         content:
//             "<h1>The Hollow Earth Theory</h1><p>Exploring the evidence that supports the claim of a hollow Earth, home to an advanced civilization and exotic ecosystems.</p>",
//         keywords: "hollow,earth,theory,civilization,ecosystem",
//     },
//     {
//         title: "The Reptilian Elite",
//         summary: "Unmasking the reptilian beings that walk among us and control society.",
//         image: "https://source.unsplash.com/400x600/?reptilian,elite",
//         content:
//             "<h1>The Reptilian Elite</h1><p>Exposing the alleged shape-shifting reptilian beings that have infiltrated human society, manipulating events from the shadows.</p>",
//         keywords: "reptilian,elite,control,society,shape-shift",
//     },
//     {
//         title: "HAARP Weather Control",
//         summary: "The hidden agenda of the HAARP program and its capabilities in weather manipulation.",
//         image: "https://source.unsplash.com/400x600/?haarp,weather",
//         content:
//             "<h1>HAARP Weather Control</h1><p>Exploring the controversial HAARP program, and investigating its alleged capabilities in manipulating weather patterns and causing natural disasters.</p>",
//         keywords: "HAARP,weather,control,manipulation,disaster",
//     },
//     {
//         title: "The Philadelphia Experiment",
//         summary: "The US Navy’s alleged involvement in a secret experiment that resulted in teleportation and time travel.",
//         image: "https://source.unsplash.com/400x600/?philadelphia,experiment",
//         content:
//             "<h1>The Philadelphia Experiment</h1><p>Exploring the clandestine military experiment said to have achieved teleportation and time travel, and the enigmatic figures connected to this mystery.</p>",
//         keywords: "philadelphia,experiment,teleportation,time,travel",
//     }
// ];
