import React from "react";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../context/AuthContext";

const Header = ({ style, createPageDraftCopy }) => {
  const navigate = useNavigate();
  const { contributor, setSnackbar } = useAuthContext();

  return (
    <header
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "12px 32px",
        width: "100%",
        boxSizing: "border-box",
        ...style,
      }}
    >
      <h1
        onClick={() => {
          navigate("/");
        }}
        style={{ textAlign: "center", cursor: "pointer" }}
      >
        Critical Factbook
      </h1>
      <div style={{display: 'flex', gap: '40px'}}>
        <div
          className="outlined-button"
          style={{
            border: "2px solid black",
            borderRadius: "4px",
            padding: "3px 24px",
          }}
          onClick={() => {
            setSnackbar({message: 'this is a test'})
          }}
        >
          <p style={{ fontSize: "18px" }}>Support</p>
        </div>

        {contributor && (
          <button
            style={{
              margin: 0,
              width: "auto",
            }}
            onClick={() => {
              navigate("/contributor_dashboard");
            }}
          >
            <p style={{ fontSize: "18px" }}>Dashboard</p>
          </button>
        )}
        {/* {
          !!createPageDraftCopy && (
            <button
              style={{
                margin: 0,
                width: "auto",
              }}
              onClick={createPageDraftCopy}
            >
              <p style={{ fontSize: "18px" }}>Create Draft</p>
            </button>
          )
        } */}
      </div>
    </header>
  );
};

export default Header;
