import React, { useCallback, useState } from "react";
import debounce from "../../../functions/debounce";
import { Analytics } from "aws-amplify";

const SearchBar = ({ setFilteredResults, data, style, setSearchTerm }) => {
  const debouncedHandleSearch = useCallback(
    debounce((value) => {
      handleSearch(value);
    }, 500),
    [data, setFilteredResults]
  );

  const handleChange = (event) => {
    const { value } = event.target;
    setSearchTerm && setSearchTerm(value);
    debouncedHandleSearch(value);
  };

  const handleSearch = (searchTerm) => {
    if (!searchTerm) {
      setFilteredResults([]);
      return;
    }

    if(searchTerm.length > 2) {
      Analytics.record({
        name: "search",
        attributes: { searchTerm },
      });
    }


    const searchTerms = searchTerm
      .toLowerCase()
      .split(" ")
      .filter((word) => word.length);

    const sortedTopics = data
      .map((page) => {
        const pageTags = page?.tags?.toLowerCase().split(",")

        

        const matchingKeywords = pageTags.filter((tag) =>
          searchTerms.some((searchTerm) => tag.includes(searchTerm))
        );

        return { ...page, keywordCount: matchingKeywords.length };
      })
      .filter((page) => page?.keywordCount > 0)
      .sort((a, b) => b?.keywordCount - a?.keywordCount);

    setFilteredResults(sortedTopics);
  };

  return (
    <input
    style={style}
      type="text"
      placeholder="Search"
      // value={searchTerm}
      onChange={handleChange}
    />
  );
};

export default SearchBar;
