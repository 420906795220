import { Storage } from 'aws-amplify';

const replaceKeysWithSignedUrls = async (delta) => {
    let urlKeyMap = {};

  if (!delta || !delta.ops || !Array.isArray(delta.ops)) {
    console.error('Invalid delta object');
    return delta;
  }

  // Deep copy the delta to avoid mutating the original
  const newDelta = JSON.parse(JSON.stringify(delta));

  // A map to hold promises for each S3 key retrieval
  const urlPromises = newDelta.ops.map(async (op) => {
    if (op.insert && op.insert.image) {
      const key = op.insert.image;
      try {
        const signedUrl = await Storage.get(key); 
        urlKeyMap[signedUrl] = key; // Store the key with the signed URL
        op.insert.image = signedUrl; // Replace the key with the signed URL
      } catch (error) {
        console.error(`Failed to get the signed URL for key: ${key}`, error);
      }
    }
  });

  // Wait for all the promises to resolve before returning the modified Delta
  await Promise.all(urlPromises);

  return [newDelta, urlKeyMap];
};

export default replaceKeysWithSignedUrls;