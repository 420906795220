/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createPageDraft = /* GraphQL */ `
  mutation CreatePageDraft(
    $input: CreatePageDraftInput!
    $condition: ModelPageDraftConditionInput
  ) {
    createPageDraft(input: $input, condition: $condition) {
      id
      label
      summary
      thumbnail
      content
      tags
      status
      contributorID
      pageID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updatePageDraft = /* GraphQL */ `
  mutation UpdatePageDraft(
    $input: UpdatePageDraftInput!
    $condition: ModelPageDraftConditionInput
  ) {
    updatePageDraft(input: $input, condition: $condition) {
      id
      label
      summary
      thumbnail
      content
      tags
      status
      contributorID
      pageID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deletePageDraft = /* GraphQL */ `
  mutation DeletePageDraft(
    $input: DeletePageDraftInput!
    $condition: ModelPageDraftConditionInput
  ) {
    deletePageDraft(input: $input, condition: $condition) {
      id
      label
      summary
      thumbnail
      content
      tags
      status
      contributorID
      pageID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createContributor = /* GraphQL */ `
  mutation CreateContributor(
    $input: CreateContributorInput!
    $condition: ModelContributorConditionInput
  ) {
    createContributor(input: $input, condition: $condition) {
      id
      Pages {
        items {
          id
          label
          summary
          thumbnail
          content
          tags
          status
          contributorID
          pageID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      name
      accessLevel
      PageDrafts {
        items {
          id
          label
          summary
          thumbnail
          content
          tags
          status
          contributorID
          pageID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateContributor = /* GraphQL */ `
  mutation UpdateContributor(
    $input: UpdateContributorInput!
    $condition: ModelContributorConditionInput
  ) {
    updateContributor(input: $input, condition: $condition) {
      id
      Pages {
        items {
          id
          label
          summary
          thumbnail
          content
          tags
          status
          contributorID
          pageID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      name
      accessLevel
      PageDrafts {
        items {
          id
          label
          summary
          thumbnail
          content
          tags
          status
          contributorID
          pageID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteContributor = /* GraphQL */ `
  mutation DeleteContributor(
    $input: DeleteContributorInput!
    $condition: ModelContributorConditionInput
  ) {
    deleteContributor(input: $input, condition: $condition) {
      id
      Pages {
        items {
          id
          label
          summary
          thumbnail
          content
          tags
          status
          contributorID
          pageID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      name
      accessLevel
      PageDrafts {
        items {
          id
          label
          summary
          thumbnail
          content
          tags
          status
          contributorID
          pageID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createPage = /* GraphQL */ `
  mutation CreatePage(
    $input: CreatePageInput!
    $condition: ModelPageConditionInput
  ) {
    createPage(input: $input, condition: $condition) {
      id
      label
      summary
      thumbnail
      content
      tags
      status
      contributorID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updatePage = /* GraphQL */ `
  mutation UpdatePage(
    $input: UpdatePageInput!
    $condition: ModelPageConditionInput
  ) {
    updatePage(input: $input, condition: $condition) {
      id
      label
      summary
      thumbnail
      content
      tags
      status
      contributorID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deletePage = /* GraphQL */ `
  mutation DeletePage(
    $input: DeletePageInput!
    $condition: ModelPageConditionInput
  ) {
    deletePage(input: $input, condition: $condition) {
      id
      label
      summary
      thumbnail
      content
      tags
      status
      contributorID
      createdAt
      updatedAt
      __typename
    }
  }
`;
