/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_mobile_analytics_app_id": "3157c428e387468ca068edeba5aa29e4",
    "aws_mobile_analytics_app_region": "us-east-1",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "3157c428e387468ca068edeba5aa29e4",
            "region": "us-east-1"
        }
    },
    "aws_appsync_graphqlEndpoint": "https://yyfjolpyvfgxbbqw2dyrjg6pk4.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-nnfekbvkojag3jrgjcupdqqxkm",
    "aws_cognito_identity_pool_id": "us-east-1:c276e4ae-4004-47fa-91b9-907725398c8d",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_jw7mSpTYZ",
    "aws_user_pools_web_client_id": "3bab6nvjqfr88vic0okgrsm0r2",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 6,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "factbook-storage234447-staging",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
