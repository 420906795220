import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Home from "./home/Home";
import PageDraft from "./page_draft/PageDraft";
import Page from "./page/Page";
import SignIn from "./auth/SignIn";
import ContributorDashboard from "./contributor_dashboard/ContributorDashboard";
import "../theme/GlobalStyles.css";
import { useAuthContext } from "../context/AuthContext";
import Footer from "../components/common/Footer";

const Navigation = () => {
  const { snackbar } = useAuthContext();

  return (
    <div style={{ width: "100vw", height: "100vh" }}>
      <Router>
        <div style={{  }}>
          <main className="content">
            <Routes>
              {/* <Route path="*" element={<PageNotFound />} /> */}

              <Route path="/" element={<Home />} />
              <Route path="/signin" element={<SignIn />} />
              <Route
                path="/contributor_dashboard"
                element={<ContributorDashboard />}
              />

              <Route path="/page_draft/:pageDraftID" element={<PageDraft />} />
              <Route path="/page/:pageID" element={<Page />} />

              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </main>
          {snackbar && (
            <div
              style={{
                position: "absolute",
                bottom: "7%",
                left: "5%",
                borderRadius: "8px",
                backgroundColor: "lightgreen",
                padding: '.8em 3em',
                boxSizing: 'border-box'
              }}
            >
              <h4 style={{ fontWeight: "bold" }}>{snackbar?.message}</h4>
            </div>
          )}
        </div>
      </Router>
    </div>
  );
};

export default Navigation;
