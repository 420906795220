import React, { useEffect, useState } from "react";
import SearchBar from "../home/components/SearchBar";
import Header from "../../components/common/Header";
import colors from "../../theme/colors";
import PageGrid from "../home/components/PageGrid";
import { useAuthContext } from "../../context/AuthContext";
import { API, Storage } from "aws-amplify";
import { listPageDrafts } from "../../graphql/queries";
import Footer from "../../components/common/Footer";

export const createPageDraft = /* GraphQL */ `
  mutation CreatePageDraft(
    $input: CreatePageDraftInput!
    $condition: ModelPageDraftConditionInput
  ) {
    createPageDraft(input: $input, condition: $condition) {
      id
      label
      summary
      thumbnail
      tags
      status
      contributorID
      createdAt
      updatedAt
      __typename
    }
  }
`;

const ContributorDashboard = () => {
  const [pageDrafts, setPageDrafts] = useState([]); //all page drafts, if accessLevel is default this is same as userPageDrafts
  const [filteredPageDrafts, setFilteredPageDrafts] = useState([]); //page drafts that match the search query

  const [foreignPageDrafts, setForeignPageDrafts] = useState([]); //page drafts that are not created by the current contributor
  const [userPageDrafts, setUserPageDrafts] = useState([]); //page drafts that are created by the current contributor

  const { contributor } = useAuthContext();

  //filter page drafts into userPageDrafts and foreignPageDrafts
  useEffect(() => {}, [pageDrafts, contributor?.id]);

  const getPageDrafts = async () => {
    const pageDraftResponse = await API.graphql({
      query: listPageDrafts,
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });

    const pageDrafts = pageDraftResponse.data.listPageDrafts.items;

    const pageDraftThumbnailPromises = pageDrafts.map((pageDraft) => {
      if (!pageDraft.thumbnail) return null;
      return Storage.get(pageDraft.thumbnail, { level: "public" });
    });
    const pageDraftThumbnails = await Promise.all(pageDraftThumbnailPromises);
    const pageDraftsWithThumbnails = pageDrafts.map((pageDraft, index) => {
      return {
        ...pageDraft,
        thumbnail: pageDraftThumbnails[index],
      };
    });

    setPageDrafts(pageDraftsWithThumbnails);

    let userPageDrafts = [];
    let foreignPageDrafts = [];
    pageDraftsWithThumbnails.forEach((pageDraft) => {
      if (pageDraft.contributorID === contributor.id) {
        userPageDrafts.push(pageDraft);
      } else {
        foreignPageDrafts.push(pageDraft);
      }
    });

    //sort pending foreign drafts to the top, then sort by updatedAt
    foreignPageDrafts.sort((a, b) => {
      if (a.status === "PENDING" && b.status !== "PENDING") return -1;
      if (a.status !== "PENDING" && b.status === "PENDING") return 1;
      return a.updatedAt > b.updatedAt ? -1 : 1;
    });

    setUserPageDrafts(userPageDrafts);
    setForeignPageDrafts(foreignPageDrafts);
  };

  useEffect(() => {
    if (contributor?.id) getPageDrafts();
  }, [contributor?.id]);

  const createNewDraft = async () => {
    //todo make api endpoint so that non admin or moderator contributors' drafts are instantiated with status DRAFT
    const newPageDraftResponse = await API.graphql({
      query: createPageDraft,
      variables: {
        input: {
          label: "New Page",
          summary: "Clear and concise summary",
          // content: {},
          tags: "",
          // status: "DRAFT",
          contributorID: contributor.id,
        },
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });

    const newPageDraft = newPageDraftResponse.data.createPageDraft;
    setPageDrafts([newPageDraft, ...pageDrafts]);
  };

  if (!contributor?.id)
    return (
      <h1>
        Fetching contributor... If the issue persists, please contact support
      </h1>
    );

  return (
    <div style={{display: 'flex', flexDirection: 'column'}}>
      <div
        style={{
          width: "100vw",
          height: "100vh",
          backgroundColor: colors.defaultBackground,
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Header />
        <SearchBar
          setFilteredResults={setFilteredPageDrafts}
          data={pageDrafts}
          style={{
            padding: ".7rem 2rem",
          }}
        />
        <button
          style={{
            margin: "40px 0px",
            padding: "1rem 3rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          onClick={() => createNewDraft()}
        >
          Create Page Draft
        </button>
        <h2
          style={{
            marginRight: "auto",
            marginLeft: "3%",
            marginBottom: "25px",
          }}
        >
          {filteredPageDrafts.length ? "Search results" : "Your drafts"}
        </h2>
        <PageGrid
          pages={
            filteredPageDrafts.length ? filteredPageDrafts : userPageDrafts
          }
          showStatus
          isDraft
        />
        {!filteredPageDrafts.length && (
          <div>
            <div
              style={{
                height: "3px",
                width: "90%",
                backgroundColor: "black",
                margin: "50px 0px",
              }}
            />
            <h2
              style={{
                marginRight: "auto",
                marginLeft: "3%",
                marginBottom: "25px",
              }}
            >
              Other contributor's drafts
            </h2>
            <PageGrid
              pages={!filteredPageDrafts.length ? foreignPageDrafts : null}
              showStatus
              isDraft
            />
      <Footer />
          </div>
        )}
      </div>
      {filteredPageDrafts.length && (
        <Footer />
      )  
      }
    </div>
  );
};

export default ContributorDashboard;
