const replaceSignedUrlsWithKeys = (delta, urlKeyMap) => {
    if (!delta || !delta.ops || !Array.isArray(delta.ops)) {
      console.error("Invalid delta object");
      return delta;
    }

    // Deep copy the delta to avoid mutating the original
    const newDelta = JSON.parse(JSON.stringify(delta));

    newDelta.ops.forEach((op) => {
      if (op.insert && op.insert.image) {
        const url = op.insert.image;
        const key = urlKeyMap[url];
        if (key) {
          op.insert.image = key; // Replace the URL with the corresponding key
        } else {
          console.warn(`Key not found for URL: ${url}`);
        }
      }
    });

    return newDelta;
  };

    export default replaceSignedUrlsWithKeys;