import React from "react";

const Footer = ({style}) => {
  //to offset it so it renders below the screen when it starts (so it can scroll up)

  return (
    <footer
      style={{
        width: "100dvw",
        height: "120px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "black",
        marginTop: "200px",
        ...style,
      }}
    >
      <h1 style={{ color: "white" }}>Project C.E.A.R</h1>
    </footer>
  );
};

export default Footer;
