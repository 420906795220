import React, { useState, useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { Auth } from "aws-amplify";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Header from "../../components/common/Header";
import { BeatLoader } from "react-spinners";

const SignIn = () => {
  //todo add in loading state, animations, change button name etc
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [error, setError] = useState(null);

  const [user, setUser] = useState(null); // Used to store the user object from cognito when a new password is required
  const { checkAuth, authUser } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const signIn = async () => {
    setLoading(true);
    try {
      const user = await Auth.signIn(email, password);
      if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        // User needs to set a new password
        setUser(user);
      } else {
        checkAuth(); // Updating the auth user in context
        navigate("/");
      }
    } catch (error) {
      setError(error.message);
      console.error("Error signing in", error);
    }

    setLoading(false);
  };

  const completeNewPassword = async () => {
    //update the password
    setLoading(true);
    try {
      await Auth.completeNewPassword(user, newPassword);
    } catch (error) {
      setLoading(false);
      setError(error.message);
      console.error("Error updating password", error);
      return;
    }

    //this is the first time user is logging in, so create the contributor by calling the api endpoint
    try {
      const currentUser = await checkAuth(); // Updating the auth user in context
      const session = await Auth.currentSession();
      const token = session.getIdToken().getJwtToken();

      await axios.post(
        process.env.REACT_APP_CONTRIBUTOR_API_ENDPOINT,
        { userID: currentUser.attributes.sub, name: currentUser.username },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      checkAuth();
    } catch (error) {
      alert("Error creating contributor, contact for help", error);
    }
    setLoading(false);
    navigate("/");
  };

  const validForm = () => {
    return (
      email &&
      /\S+@\S+\.\S+/.test(email) &&
      password &&
      password.length > 5
    );
  }

  //todo make this look like effort was put into it
  return (
    <div
      style={{
        width: "100dvw",
        height: "100dvh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Header />
      {authUser && (
        <h2 style={{ marginTop: "40px" }}>
          Currently authenticated as {authUser?.attributes.email}
        </h2>
      )}
      <h1 style={{ margin: "40px 0px" }}>
        {user ? "Update Password" : "Sign In"}
      </h1>
      {user ? (
        <>
          <input
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            onKeyDown={(e) => {
              e.key === "Enter" && newPassword.length > 5 && completeNewPassword();
            }
            }
            placeholder="New Password"
          />
          <p>{error}</p>
          <button
            disabled={newPassword.length < 6}
            onClick={completeNewPassword}
          >
            {loading ? <BeatLoader size={10} color="white" /> : "Update Password"}
          </button>
        </>
      ) : (
        <>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            onKeyDown={(e) => {
              e.key === "Enter" && validForm() && signIn();
            }}
          />
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
            //when enter is pressed, if the button is not disabled, sign in
            onKeyDown={(e) => {
              e.key === "Enter" && validForm() && signIn();
            }}
          />
          <p>{error}</p>
          <button
            disabled={
             !validForm() || loading
            }
            onClick={signIn}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {loading ? <BeatLoader size={10} color="white" /> : "Sign In"}
          </button>
        </>
      )}
    </div>
  );
};

export default SignIn;
