import React, { useState, useEffect, useRef } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useParams } from "react-router-dom";
import Header from "../../components/common/Header";
import "./Page.css";
import { API, Analytics } from "aws-amplify";
import { getPage } from "../../graphql/queries";
import Footer from "../../components/common/Footer";
import { RingLoader, GridLoader, HashLoader } from "react-spinners";

const Page = ({ previewContent }) => {
  const [content, setContent] = useState();
  const quillRef = useRef(null);

  //get the page id from the url params
  const { pageID } = useParams();

  const fetchPage = async () => {
    const pageResponse = await API.graphql({
      query: getPage,
      variables: { id: pageID },
    });

    const page = pageResponse?.data?.getPage;

    if (!page) {
      setContent(null);
      return;
    }
    //convert from JSON to object
    const content = JSON.parse(page.content);
    setContent(content);

    const timestamp = new Date().toISOString();
    Analytics.record({
      name: "pageView",
      attributes: { page: page?.label, pageID, timestamp },
    });
  };

  useEffect(() => {
    if (!previewContent && pageID) {
      fetchPage();
    }
  }, [pageID]);

  useEffect(() => {
    //if previewContent is passed in, use that
    const quillContent = previewContent || content;

    if (quillContent && quillRef.current) {
      const quill = quillRef.current.getEditor();
      quill.setContents(quillContent); // Set Quill content
    }
  }, [content, previewContent]);

  const createPageDraftCopy = () => {
    //needs to be an api, copy all of the page draft data and create a new page draft and copy the images to page_drafts s3
  };

  if (!content && !previewContent) {
    const spinners = [
      // <RingLoader color="black" />,
      <HashLoader color="black" />,
      <GridLoader color="black" />,
    ];

    // const spinner = spinners[Math.floor(Math.random() * spinners.length)];

    const spinner = <GridLoader color="black" />;

    return (
      <div>
        <Header />

        <div
          style={{
            width: "100dvw",
            height: "100dvh",
            // backgroundColor: colors.defaultBackground,
            alignItems: "center",
            // justifyContent: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div style={{ marginTop: "20%" }}>{spinner}</div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div
        style={{
          width: "100dvw",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {!previewContent && (
          <Header
            style={{ marginBottom: "50px" }}
            createPageDraftCopy={createPageDraftCopy}
          />
        )}
        <div className="page-container">
          <ReactQuill
            ref={quillRef}
            readOnly={true}
            theme="snow"
            modules={{ toolbar: false }}
            style={{ border: "none" }}
          />
        </div>
        <Footer
          style={{
            //set to border-box
            boxSizing: "border-box",
            padding: "2rem",
            marginTop: "max(auto, 100px)",
          }}
        />
      </div>
    </div>
  );
};

export default Page;
