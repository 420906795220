import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import Navigation from "./pages/Navigation";
import { Amplify } from "aws-amplify";
import awsconfig from "./aws-exports";
import AuthContextProvider from "./context/AuthContext";

Amplify.configure({...awsconfig,
  // Storage: {
  //   AWSS3: {
  //     bucket: "factbook-bucket",
  //     region: "us-east-1",
  //   },
  // }
});

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <AuthContextProvider>
      <Navigation />
    </AuthContextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
