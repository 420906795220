import React, { createContext, useState, useEffect, useRef } from "react";
import { API, Auth } from "aws-amplify";
import { getContributor } from "../graphql/queries";

export const AuthContext = createContext();

export const useAuthContext = () => React.useContext(AuthContext);

const AuthContextProvider = (props) => {
  const [authUser, setAuthUser] = useState(null);
  const [contributor, setContributor] = useState(null);
  const [accessLevel, setAccessLevel] = useState('DEFAULT');
  
  const [snackbar, setSnackbar] = useState(null);
  const timeoutRef = useRef();  // Using useRef to keep track of the timeout

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }


    if (snackbar) {
      timeoutRef.current = setTimeout(() => {
        setSnackbar(null); 
        timeoutRef.current = null;  
      }, snackbar?.time || 3000);  
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [snackbar]);

  const checkAuth = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      setAuthUser(user);

      //set access level to highest cognito group, ADMIN > MODERATOR > DEFAULT
      const groups =
        user.signInUserSession.accessToken.payload["cognito:groups"];

      if (groups) {
        groups.includes("ADMIN")
          ? setAccessLevel("ADMIN")
          : groups.includes("MODERATOR")
          ? setAccessLevel("MODERATOR")
          : setAccessLevel("DEFAULT");
      }

      //if the user is authenticated, get the corresponding contributor
      if (user) {
        const contributorResponse = await API.graphql({
          query: getContributor,
          variables: { id: user.attributes.sub },
          authMode: "AMAZON_COGNITO_USER_POOLS",
        });
        setContributor(contributorResponse.data.getContributor);
      }

      return user;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    checkAuth();
  }, []);

  return (
    <AuthContext.Provider value={{ authUser, contributor, checkAuth, accessLevel, snackbar, setSnackbar }}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
