import React, { useEffect, useState } from "react";
import "./PageGrid.css";
import { useNavigate } from "react-router-dom";
import { Analytics } from "aws-amplify";
const PageGrid = ({ pages, showStatus, isDraft }) => {
  const navigate = useNavigate();

  const gridStyle = {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
    gap: "1rem",
    width: "80%",
    margin: "0 auto",
  };
  // enum Status {
  //   DRAFT
  //   PENDING
  //   APPROVED
  //   PUBLISHED
  //   REJECTED
  //   ARCHIVED
  // }
  const statusColorDict = {
    DRAFT: "turquoise",
    PENDING: "orange",
    APPROVED: "green",
    PUBLISHED: "darkgreen",
    REJECTED: "red",
    ARCHIVED: "purple",
  };

  return (
    <div style={gridStyle}>
      {pages.map((page) => (
        <div style={{marginBottom: '2rem'}}>
          <div
            onClick={() => {
              if(isDraft) {
                navigate(`/page_draft/${page.id}`)
              }
              else {
                navigate(`/page/${page.id}`)

                const timestamp = new Date().toISOString();
                Analytics.record({ name: "pageClick", attributes: { page: page?.label, pageID: page.id, timestamp } });
              }
            }}
            className="page-card"
            key={page?.id}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "flex-start",
              backgroundColor: "#fff",
              borderRadius: "8px",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              padding: "1rem",
              //fill rest of space to match largest card
              height: "90%",
            }}
          >
            <img
              style={{
                width: "100%",
                aspectRatio: "5/3",
                borderRadius: "8px",
                objectFit: "cover",
                marginBottom: "1rem",
              }}
              src={page.thumbnail}
              alt={page.thumbnail}
            />
            <h3
              style={{
                fontSize: "1.2rem",
                fontWeight: "bold",
                marginBottom: "0.5rem",
              }}
            >
              {page.label}
            </h3>
            <p
              style={{
                fontSize: "1rem",
                textAlign: "center",
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: "2",
                WebkitBoxOrient: "vertical",
              }}
            >
              {page.summary}
            </p>
          </div>
          {showStatus && (
            <p
              style={{
                marginTop: "0.5rem",
                fontSize: "0.8rem",
                textAlign: "center",
                color: statusColorDict[page.status],
                fontWeight: "bold",
              }}
            >
              {page.status}{" "}
              <span style={{ color: "black" }}>
                •{" "}
                {
                  //show time ago in minutes ago or hours ago if less than 1 day ago, else show date
                  new Date(page.updatedAt).toLocaleDateString("en-US", {
                    month: "short",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                  })
                }
              </span>{" "}
            </p>
          )}
        </div>
      ))}
    </div>
  );
};

export default PageGrid;
